import React from 'react';
import logo from './wpcc-logo.png';

const Header = () => (
  <header className="bg-white shadow-md py-4 sm:py-6">
    <div className="container mx-auto flex justify-center px-4 sm:px-6">
      <img src={logo} alt="WP Campus Connect Logo" style={{ maxWidth: '320px' }} />
    </div>
  </header>
);

export default Header;
